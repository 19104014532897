var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticStyle:{"font-size":"0","line-height":"1.5"},attrs:{"cellspacing":"0","cellpadding":"0","border":"0","role":"presentation"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"vertical-align":"top"}},[_c('avatar',{attrs:{"show-avatar":_vm.showAvatar,"src":_vm.image,"size":_vm.options.avatar.size,"roundness":_vm.options.avatar.roundness}})],1),_c('td',{staticStyle:{"vertical-align":"top"}},[_c('table',{staticStyle:{"font-size":"0"},attrs:{"cellspacing":"0","cellpadding":"0","border":"0","role":"presentation"}},[_c('tbody',[_c('tr',[_c('td',[_c('span',{style:([_vm.fontBase, {
                      fontSize: ((_vm.options.font.size + 2) + "px"),
                      color: _vm.options.color.mainPreview || _vm.options.color.main,
                      fontWeight: 700
                    }])},[_vm._v(_vm._s(_vm.mainFields[0].value))])])]),_c('tr',[_c('td',[_c('span',{style:(_vm.fontBase)},[_vm._v(_vm._s(_vm.mainFields[1].value))]),(_vm.mainFields[2].value && _vm.options.separator !== 'br')?_c('span',[_c('span',{style:(_vm.fontBase)},[_vm._v(" "+_vm._s(_vm.options.separator)+" ")]),_c('span',{style:(_vm.fontBase)},[_vm._v(_vm._s(_vm.mainFields[2].value))])]):_vm._e()])]),(_vm.options.separator === 'br')?_c('tr',[_c('td',[_c('span',{style:(_vm.fontBase)},[_vm._v(_vm._s(_vm.mainFields[2].value))])])]):_vm._e(),_c('tr',[_c('td',[_c('table',{staticStyle:{"margin-top":"5px","font-size":"0"},attrs:{"cellspacing":"0","cellpadding":"0","border":"0","role":"presentation"}},[_vm._l((_vm.otherFields),function(item){return [(item.value)?_c('tr',{key:item.name},[_c('td',[_c('span',{style:([_vm.fontBase, {
                              color: _vm.options.color.secondaryPreview || _vm.options.color.secondary,
                              fontWeight: 600
                            }])},[_vm._v(_vm._s(item.name)+": ")]),(item.type === 'link')?_c('a',{staticStyle:{"text-decoration":"none","color":"inherit"},style:(_vm.fontBase),attrs:{"href":_vm.formatLink(item.value)}},[_vm._v(_vm._s(item.value))]):_vm._e(),(item.type === 'email')?_c('a',{staticStyle:{"text-decoration":"none","color":"inherit"},style:(_vm.fontBase),attrs:{"href":("mailto:" + (item.value))}},[_vm._v(_vm._s(item.value))]):_vm._e(),(item.type === 'text')?_c('span',{style:(_vm.fontBase)},[_vm._v(_vm._s(item.value))]):_vm._e()])]):_vm._e()]})],2)])]),(_vm.socials.installed.length)?_c('tr',[_c('td',[_c('table',{staticStyle:{"margin-top":"5px","margin-left":"-2px","font-size":"0"},attrs:{"cellspacing":"2","cellpadding":"0","border":"0","role":"presentation"}},[_c('tbody',[_c('tr',_vm._l((_vm.socials.installed),function(item){return _c('td',{key:item.name,staticStyle:{"width":"20px","height":"20px","border-radius":"3px"},style:({
                            backgroundColor: _vm.options.color.mainPreview || _vm.options.color.main,
                          }),attrs:{"align":"center"}},[_c('a',{attrs:{"href":_vm.formatLink(item.link)}},[_c('img',{staticStyle:{"display":"table-cell","vertical-align":"middle"},attrs:{"width":"12px","src":(_vm.s3url + "/icons/" + (item.icon) + ".png"),"alt":("social-icon-" + (item.icon))}})])])}),0)])])])]):_vm._e()])])])])])]),(_vm.isAdded('mobileApp'))?_c('table',{staticStyle:{"margin-top":"10px","font-size":"0"},attrs:{"cellspacing":"0","cellpadding":"0","border":"0","role":"presentation"}},[_c('tbody',[_c('tr',[(_vm.addons.mobileApp.appStore.link)?_c('td',[_c('a',{attrs:{"href":_vm.addons.mobileApp.appStore.link}},[_c('img',{staticStyle:{"height":"35px","margin-right":"5px"},attrs:{"src":_vm.addons.mobileApp.appStore.img,"alt":"app store badge"}})])]):_vm._e(),(_vm.addons.mobileApp.googlePlay.link)?_c('td',[_c('a',{attrs:{"href":_vm.addons.mobileApp.googlePlay.link}},[_c('img',{staticStyle:{"height":"35px"},attrs:{"src":_vm.addons.mobileApp.googlePlay.img,"alt":"google play badge"}})])]):_vm._e()])])]):_vm._e(),(_vm.isAdded('disclaimer'))?_c('table',{staticStyle:{"font-size":"0","color":"#888","margin-top":"10px"},attrs:{"cellspacing":"0","cellpadding":"0","border":"0","role":"presentation"}},[_c('tbody',[_c('tr',[_c('td',[_c('span',{style:(_vm.fontBase)},[_vm._v(_vm._s(_vm.addons.disclaimer))])])])])]):_vm._e(),(_vm.template.promoteSignature)?_c('promote-signature'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }